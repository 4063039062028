.mobile{
    background-color: white;
    height: 85vh;
    width: 100vw;
    position: absolute;
    right: 0px;
    top: 10px;
    margin-top: 69px;
    padding:  18px 12px;
    z-index: 3;
    display: flex;
    flex-direction: column;
}
.close-icon{
    display: flex;
    justify-content: flex-end;
}

.mobile-options{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 24px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.mobile-option{
    font-size:20px ;
    padding-top: 30px;
    font-weight: 700;
    margin: 12px 0px;
    display: flex;
}
.mobile-option > a{
    text-decoration: none;
    display: flex;
    color: black;
}