.carousel {

    height:500px;
    width: 100%;
    background-color: black;

}

.carousel-item img {

    height:500px;
    width: 100%;
        
    object-fit:contain;       
}

.carousel-caption{

    padding: 0rem 1rem 0rem 1rem;
    font-size: 1.5rem;
    border-radius: 20px;
    align-items: center;


    transition: all 0.5s;
    z-index: 9;
    background-image: linear-gradient(
        360deg,
        transparent,
        rgba(0, 0, 0, 0.65), 
        rgb(0, 0, 0)
    );

}

@media (max-width: 768px) {
    .carousel {        
        height:300px;
    }
    
    .carousel-item img {
    
        height:300px;

        object-fit: contain;

    }

    .carousel-caption{

        font-size: 1rem;
    }
}



