.about .content h3 {
    font-weight: 700;
    font-size: 28px;
    margin-top: 2.5rem;
    font-family: "Poppins", sans-serif;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  .swiper-card-wrapper {
    height: 300px; /* Set the desired fixed height for each card */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .swiper-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .swiper-card img {
    width: 100%;
    height: auto;
    object-fit: contain; /* Make the image cover the entire space while maintaining its aspect ratio */
  }
  
  
  
  .about .content ul li {
    padding: 0 0 8px 26px;
    position: relative;
    margin-top: 1rem;
  }
  .about .content ul i {
    position: absolute;
    font-size: 20px;
    left: 0;
    top: -3px;
    color: #ffc451;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }

  .clients .swiper-slide{
    max-height: 700px;
    align-items: center;
    text-align: center;
    align-self: center;
  }

  .clients .swiper-slide img{
    max-width: 80%;
  } 

  .clients .swiper-slide:hover img{
    transform: scale(1.2);
    
  }

  .clients .swiper-slide img {
    transition: all ease-in-out 0.3s;
  }

  .clients .swiper-slide {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

    
  
  .clients .swiper-slide::before {
    content: "";
    background: rgba(21, 21, 21, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    border-radius: 10px;
    opacity: 0;
  }

 

  


  .clients .swiper-slide .customer-links {
    text-align: center;
    z-index: 4;
  }

  
  .clients .swiper-slide .customer-links a {
    color: #fff;
    margin: 0 5px 0 0;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }

  .clients .swiper-slide .customer-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
  }
  
  .clients .swiper-slide .customer-links a:hover {
    color: #ffc451;
  }

  .clients .swiper-slide:hover .customer-info {
    opacity: 1;
  }

  .clients .swiper-slide:hover::before {
    opacity: 1;
  }
  
  
  

  .clients {
    max-height: 30%;
    padding-top: 20px;
    cursor: pointer;
  }
  
  .clients .swiper-slide img {
    /* opacity: 0.5; */
    transition: 0.3s;
    /* filter: grayscale(100); */
  }
  
  .clients .swiper-slide img:hover {
    filter: none;
    opacity: 1;
  }
  
  .clients .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    background-color: #ddd;
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffc451;
  }