#footer {
    background: black;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }


  
  #footer .footer-top {
    background: #151515;
    border-bottom: 1px solid #222222;
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 28px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  #footer .footer-top .footer-info h3 span {
    color: #ffc451;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #292929;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #ffc451;
    color: #151515;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ffc451;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #ffc451;
  }
  
  

  
 
  
  #footer .copyright {
    text-align: center;
    padding-top: 30px;
    text-decoration: none;
  }

  .credits{
    cursor: pointer;
  }

  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }

  #footer .credits a{
      text-decoration: none;
      color: #ffc451;
  }

  #footer .footer-top .footer-info h3 img{
    max-height: 40px;
    transition: transform .7s ease-in-out;
    box-shadow: 0 0 10px #ffffff;
    background-color: white;
    border-radius: 50%;
    
  }
  #footer .footer-top .footer-info h3 img:hover{
    transform: rotate(360deg);
    box-shadow: 0 0 40px #ffffff;
  }
