.product .product-item {
    margin-bottom: 30px;
  }
  
  .product #product-flters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  
  .product #product-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 15px 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
  }
  
  .product #product-flters li:hover,
  .product #product-flters li.filter-active {
    color: #151515;
    background: #ffc451;
  }
  
  .product #product-flters li:last-child {
    margin-right: 0;
  }
  
  .product .product-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(21, 21, 21, 0.6);
  }
  
  .product .product-wrap::before {
    content: "";
    background: rgba(21, 21, 21, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }
  
  .product .product-wrap img {
    transition: all ease-in-out 0.3s;
  }
  
  .product .product-wrap .product-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
  }
  
  .product .product-wrap .product-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }
  
  .product .product-wrap .product-info p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-style: italic;
  }
  
  .product .product-wrap .product-links {
    text-align: center;
    z-index: 4;
  }
  
  .product .product-wrap .product-links a {
    color: #fff;
    margin: 0 5px 0 0;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .product .product-wrap .product-links a:hover {
    color: #ffc451;
  }
  
  .product .product-wrap:hover::before {
    opacity: 1;
  }
  
  .product .product-wrap:hover img {
    transform: scale(1.2);
  }
  
  .product .product-wrap:hover .product-info {
    opacity: 1;
  }

  .image-scroll{
    background-size: cover;
  }
