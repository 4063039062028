.services .icon-box {
    text-align: center;
    border-radius: 10px;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
    margin-bottom: 1.5rem;
  }

  .services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #ffc451;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
  }
  .helper{
    text-decoration: none;
    color: black;
  }
  .helper:hover{
    color: black;
  }
  .services .icon-box .icon i {
    color: #151515;
    font-size: 28px;
    transition: ease-in-out 0.3s;
  }

  .services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }

  .services .icon-box h4 a {
    color: #151515;
    transition: ease-in-out 0.3s;
    text-decoration: none;
  }

  .services .icon-box h4 a:hover {
    color: #ffc451;
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .services .icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
  }