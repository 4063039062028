#hero {
    width: 100%;
    height: 100vh;
    background: url("/src/assets/images/homeback.jpeg") top center;
    background-size: cover;
    position: relative;
  }
  
  #hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .container {
    position: relative;
    padding-top: 74px;
    text-align: center;
  }
  
  #hero h1 {
    margin: 0;
    font-size: 35px;
    font-weight: 700;
    line-height: 64px;
    color: #fff;
    font-family: "Poppins", sans-serif;
  }
  
  #hero h1 span {
    color: #ffc451;
  }
  
  #hero h2 {
    color: rgba(255, 255, 255, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
  }
  
  #hero .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
  }
  
  #hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: #ffc451;
  }
  
  #hero .icon-box h3 {
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
    line-height: 26px;
  }
  
  #hero .icon-box h3 a {
    color: #fff;
    transition: ease-in-out 0.3s;
    text-decoration: none;
  }
  
  #hero .icon-box h3 a:hover {
    color: #ffc451;
  }
  
  #hero .icon-box:hover {
    border-color: #ffc451;
  }
  .home-logo img{
    width: 20%;
    transition: transform .7s ease-in-out;
    border-radius: 50%;
    margin-bottom: 4rem;
    box-shadow: 0px 0px 40px white;
    background-color: white;
    
  }

  .home-logo img:hover{
    transform: rotate(360deg);
    box-shadow: 0px 0px 100px white;
    cursor: pointer;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
    
  }
  
  @media (max-width: 768px) {
    #hero {
      height: auto;
      background-repeat: no-repeat;
    }
  
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #hero h2 {
      font-size: 20px;
      line-height: 24px;
    }
    .home-logo img{
      width: 60%;
      margin-bottom: 2rem;
    }
  }

